var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.list && _vm.list.length > 0
        ? _c(
            "a-row",
            { attrs: { gutter: 16 } },
            _vm._l(_vm.list, function(item) {
              return _c(
                "a-col",
                { key: item.id, staticClass: "mb-20", attrs: { span: 8 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        hoverable: "",
                        title: item.redPacketTitle,
                        headStyle: _vm.computedHeaderStyle(item.redPacketType),
                        bordered: false
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "color-purple font-weight-bold",
                          attrs: { slot: "extra" },
                          slot: "extra"
                        },
                        [
                          _vm._v(_vm._s(item.userName)),
                          _c("span", { staticClass: "color-green" }, [
                            _vm._v("【" + _vm._s(item.userId) + "】")
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "mt-5" }, [
                        _vm._v("优惠券金额："),
                        _c(
                          "span",
                          { staticClass: "color-red font-weight-bold" },
                          [
                            _vm._v(
                              _vm._s(
                                item.redPacketType
                                  ? "￥" + item.money
                                  : "免手续费"
                              )
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "mt-5" }, [
                        _vm._v("优惠券满减金额："),
                        _c(
                          "span",
                          { staticClass: "color-red font-weight-bold" },
                          [_vm._v("￥" + _vm._s(item.fullDecrementPrice))]
                        )
                      ]),
                      _c("div", { staticClass: "mt-5" }, [
                        _vm._v("优惠券使用状态： "),
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-bold",
                            class:
                              _vm.couponStatusByUseClassType[item.writeOffState]
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.computedCouponStatusByUseTypeMapText(
                                  item.writeOffState
                                )
                              ) + " "
                            ),
                            item.productId
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "color-gray cur-pot",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleGoToProductList(
                                          item.productId
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("【" + _vm._s(item.productId) + "】")]
                                )
                              : _vm._e()
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "mt-5" }, [
                        _vm._v("优惠券说明："),
                        _c("span", { staticClass: "color-orange" }, [
                          _vm._v(_vm._s(item.redPacketDesc))
                        ])
                      ]),
                      _c("div", { staticClass: "mt-5" }, [
                        _vm._v("优惠券领取时间："),
                        _c("span", { staticClass: "color-gray" }, [
                          _vm._v(_vm._s(item.createTime))
                        ])
                      ]),
                      _c("div", { staticClass: "mt-5" }, [
                        _vm._v("优惠券过期时间："),
                        _c("span", { staticClass: "color-gray" }, [
                          _vm._v(_vm._s(item.expireTime))
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            }),
            1
          )
        : _c("a-empty")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }