import {objArrMapObj} from "@/untils/otherEvent"

/** 优惠卷使用状态 */
export const COUPON_STATUS_BY_USE_TYPE_LIST = [
    { name: '未使用', value: 0 },
    { name: '已使用', value: 1 },
]

/** 优惠券状态使用类 */
export const COUPON_STATUS_BY_USE_CLASS_TYPE = {
    0: 'color-green',
    1: 'color-red'
}

/** 使用类型映射文案 */
export const couponStatusByUseTypeMapText = (value) => {
    return objArrMapObj('value', 'name', COUPON_STATUS_BY_USE_TYPE_LIST)[value]
}

/** 优惠券时间状态 */
export const COUPON_STATUS_BY_TIME_TYPE_LIST = [
    { name: '未过期', value: 0 },
    { name: '已逾期', value: 1 },
]

/** 优惠券时间状态 */
export const COUPON_STATUS_BY_TIME_CLASS_TYPE = {
    0: 'color-green',
    1: 'color-red'
}

/** 使用类型映射文案 */
export const couponStatusByTimeTypeMapText = (value) => {
    return objArrMapObj('value', 'name', COUPON_STATUS_BY_TIME_TYPE_LIST)[value]
}

/** 优惠券类型 */
export const COUPON_TYPE_LIST = [
    { name: '免佣金券', value: 0 },
    { name: '立减金券', value: 1 },
]

/** 优惠券类型样式类 */
export const COUPON_TYPE_CLASS_TYPE = {
    0: 'color-orange-red',
    1: 'color-blue-green'
}

/** 使用类型映射文案 */
export const couponTypeMapText = (value) => {
    return objArrMapObj('value', 'name', COUPON_TYPE_LIST)[value]
}


