<template>
  <div>
    <a-row :gutter="16" v-if="list && list.length > 0">
      <a-col :span="8" v-for="item in list" :key="item.id" class="mb-20">
        <a-card hoverable :title="item.redPacketTitle" :headStyle="computedHeaderStyle(item.redPacketType)" :bordered="false">
          <div slot="extra" class="color-purple font-weight-bold">{{ item.userName }}<span class="color-green">【{{ item.userId }}】</span></div>
          <div class="mt-5">优惠券金额：<span class="color-red font-weight-bold">{{ item.redPacketType ? `￥${item.money}` : '免手续费' }}</span></div>
          <div class="mt-5">优惠券满减金额：<span class="color-red font-weight-bold">￥{{ item.fullDecrementPrice }}</span></div>
          <div class="mt-5">优惠券使用状态：
            <span
                class="font-weight-bold"
                :class="couponStatusByUseClassType[item.writeOffState]"
            >{{ computedCouponStatusByUseTypeMapText(item.writeOffState) }}
              <span @click="handleGoToProductList(item.productId)" v-if="item.productId" class="color-gray cur-pot">【{{ item.productId }}】</span>
            </span></div>
          <div class="mt-5">优惠券说明：<span class="color-orange">{{ item.redPacketDesc }}</span></div>
          <div class="mt-5">优惠券领取时间：<span class="color-gray">{{ item.createTime }}</span></div>
          <div class="mt-5">优惠券过期时间：<span class="color-gray">{{ item.expireTime }}</span></div>
        </a-card>
      </a-col>
    </a-row>
    <a-empty v-else />
  </div>

</template>
<script>
import {
  COUPON_STATUS_BY_USE_CLASS_TYPE,
  COUPON_TYPE_CLASS_TYPE,
  couponStatusByUseTypeMapText
} from "@/views/cmsPage/dqSys/couponManage/_data"

export default {
  props: ['list'],
  data() {
    return {
      couponTypeClassType: COUPON_TYPE_CLASS_TYPE,
      couponStatusByUseClassType: COUPON_STATUS_BY_USE_CLASS_TYPE,
    }
  },
  computed: {
    computedHeaderStyle() {
      return type => {
        return type === 0 ? {color: '#2d8cf0'} : {color: 'cadetblue'}
      }
    },
    computedCouponStatusByUseTypeMapText() {
      return value => couponStatusByUseTypeMapText(value)
    }
  },
  methods: {
    /** 去往拍品列表页面 */
    handleGoToProductList(productId) {
      const origin = location.origin
      const url = `${origin}/#/cms/currencymannage/auctiongoods?productName=${productId}`;
      window.open(url)
    },
  }
}
</script>
<style scoped lang="scss">
.coupon-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
</style>