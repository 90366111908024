var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "领取优惠券",
        width: 900,
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c(
          "div",
          { staticClass: "top-box" },
          [
            _c("a-input", {
              staticClass: "w-200 mb-5",
              attrs: { allowClear: "", placeholder: "userId或手机号" },
              model: {
                value: _vm.params.userKeyWord,
                callback: function($$v) {
                  _vm.$set(_vm.params, "userKeyWord", $$v)
                },
                expression: "params.userKeyWord"
              }
            }),
            _c(
              "a-button",
              {
                staticClass: "ml-5 mb-5",
                attrs: { type: "primary" },
                on: { click: _vm.handleGetUserInfo }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        ),
        _vm.userInfo
          ? _c(
              "div",
              { staticClass: "flex-start-center flex-wrap mt-20" },
              _vm._l(_vm.userInfo, function(info) {
                return _c(
                  "a-card",
                  {
                    key: info.idNumber,
                    staticClass: "mr-20 mb-20 w-400",
                    attrs: { hoverable: "" }
                  },
                  [
                    _vm.userInfo.length > 1
                      ? _c(
                          "div",
                          { attrs: { slot: "extra" }, slot: "extra" },
                          [
                            _c("a-checkbox", {
                              attrs: { checked: info.selected },
                              on: {
                                change: function($event) {
                                  return _vm.handleChangeCheck($event, info)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-card-meta",
                      {
                        attrs: {
                          title: info.userName + "【" + info.id + "】",
                          description: "创建时间：" + info.createTime
                        }
                      },
                      [
                        _c("a-avatar", {
                          attrs: { slot: "avatar", src: info.userAvatar },
                          slot: "avatar"
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }