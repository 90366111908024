<template>
  <a-modal
      v-model="showPopup"
      title="领取优惠券"
      :width="900"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="modal-content">
      <div class="top-box">
        <a-input
            allowClear
            v-model="params.userKeyWord"
            class="w-200 mb-5"
            placeholder='userId或手机号'
        ></a-input>
        <a-button
            class="ml-5 mb-5"
            type="primary"
            @click="handleGetUserInfo"
        >搜索</a-button>
      </div>
      <div class="flex-start-center flex-wrap mt-20" v-if="userInfo">
        <a-card hoverable class="mr-20 mb-20 w-400" v-for="info in userInfo" :key="info.idNumber">
          <div slot="extra" v-if="userInfo.length > 1">
            <a-checkbox :checked='info.selected' @change="handleChangeCheck($event, info)"></a-checkbox>
          </div>
          <a-card-meta :title="info.userName + `【${ info.id }】`" :description="'创建时间：' + info.createTime">
            <a-avatar
                slot="avatar"
                :src="info.userAvatar"
            />
          </a-card-meta>
        </a-card>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {confirmPickUpCoupon, getUserPickUpCoupon} from "@/views/cmsPage/dqSys/couponManage/_apis"

export default {
  data() {
    return {
      showPopup: false,
      params: {
        userKeyWord: undefined
      },
      userInfo: ''
    }
  },
  methods: {
    /** 显示弹窗 */
    show() {
      this.showPopup = true
    },
    /** 选择需要添加的数据 */
    handleChangeCheck(e, info) {
      this.userInfo.forEach(el => {
        if (el.id === info.id) {
          el.selected = e.target.checked
        } else {
          el.selected = false
        }
      })
    },
    /** 获取用户信息 */
    async handleGetUserInfo() {
      if (!this.params.userKeyWord) return this.$message.warn('请输入用户的完整手机号，或者userID')
      this.$loading.show()
      const res = await getUserPickUpCoupon(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      res.data.forEach(el => {
        el.selected = false
      })
      this.userInfo = res.data
    },
    /** 确定领取 */
    async handleConfirm() {
      if (this.userInfo.length <= 0) return this.$message.warn('请先输入完整手机号，或者userID查询用户信息')
      let userId = ''
      if (this.userInfo.length > 1) {
        if (!this.userInfo.some(el => el.selected === true)) return this.$message.warn('请选择要添加优惠券的账户')
        userId = this.userInfo.find(el => el.selected === true).id
      } else {
        userId = this.userInfo[0].id
      }
      this.$loading.show()
      const res = await confirmPickUpCoupon({userId: userId})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('领取成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消领取 */
    handleCancel() {
      this.params = {
        phone: undefined
      }
      this.userInfo = ''
      this.showPopup = false
    }
  },
}
</script>
<style lang="scss" scoped>
.top-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.modal-content {
  width: 100%;
}
</style>