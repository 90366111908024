<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.userKeyWord"
          class="w-200 mb-5"
          placeholder='userId或手机号'
      ></a-input>
      <a-select
          allowClear
          showSearch
          class="w-200 ml-5 mb-5"
          v-model="params.writeOffState"
          placeholder="优惠券使用状态"
      >
        <a-select-option
            v-for="item of couponStatusByUseTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          allowClear
          showSearch
          class="w-200 ml-5 mb-5"
          v-model="params.isExpire"
          placeholder="优惠券逾期状态"
      >
        <a-select-option
            v-for="item of couponStatusByTimeTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          allowClear
          showSearch
          class="w-200 ml-5 mb-5"
          v-model="params.couponType"
          placeholder="优惠券类型"
      >
        <a-select-option
            v-for="item of couponTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button
          class="ml-5 mb-5"
          type="primary"
          @click="search"
      >搜索</a-button>
      <a-button
          class="ml-20 mb-5"
          @click="handleAddUserCoupon"
      >领取优惠券</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
      />
    </div>
    <div class="pagination">
      <a-pagination
          :pageSize.sync="pagination.pageSize"
          :current="pagination.current"
          :total="pagination.total"
          @change="handleChangePage"
      />
    </div>
<!--  领取优惠券  -->
    <PickUpCouponPopup ref="pickUpCouPonPopupEl" @success="getList"/>
  </div>
</template>
<script>
import {getCouponListApi} from "@/views/cmsPage/dqSys/couponManage/_apis"
import TableList from "@/views/cmsPage/dqSys/couponManage/_components/TableList/index.vue"
import {
  COUPON_STATUS_BY_TIME_TYPE_LIST,
  COUPON_STATUS_BY_USE_TYPE_LIST,
  COUPON_TYPE_LIST
} from "@/views/cmsPage/dqSys/couponManage/_data"
import PickUpCouponPopup from "@/views/cmsPage/dqSys/couponManage/_components/PickUpCouponPopup/index.vue"

export default {
  components: {
    TableList,
    PickUpCouponPopup
  },
  data() {
    return {
      data: [],
      couponStatusByUseTypeList: COUPON_STATUS_BY_USE_TYPE_LIST,
      couponStatusByTimeTypeList: COUPON_STATUS_BY_TIME_TYPE_LIST,
      couponTypeList: COUPON_TYPE_LIST,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 30,
        userKeyWord: undefined,
        writeOffState: undefined,
        isExpire: undefined,
        couponType: undefined
      },
    };
  },
  async created() {
    await this.getList()
  },
  methods: {
    /** 领取优惠券 */
    handleAddUserCoupon() {
      this.$refs.pickUpCouPonPopupEl.show()
    },
    /** 翻页 */
    handleChangePage(page) {
      this.$set(this.pagination, "current", page);
      this.$set(this.params, "pageNum", page);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getCouponListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: center;
  margin-top: 10px;
}
</style>
