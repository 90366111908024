import instance from "@/axios"

/** 获取物流信息 */
export const getCouponListApi = async (params) => {
    return instance({
        url: '/dq_admin/coupon/list',
        method: 'GET',
        params: params
    })
}

/** 查询手机号 */
export const getUserPickUpCoupon = async (params) => {
    return instance({
        url: '/dq_admin/coupon/searchUser',
        method: 'GET',
        params: params
    })
}

/** 领取用户优惠券 */
export const confirmPickUpCoupon = async (params) => {
    return instance({
        url: '/dq_admin/coupon/addUserCoupon',
        method: 'GET',
        params: params
    })
}